// order matters. a lot. see uiKIT's docs
@import "_variables.scss";
@import "~uikit/src/scss/mixins-theme.scss";
@import "_hooks.scss";
@import "~uikit/src/scss/uikit-theme.scss";
@import "_custom.scss";


html[dir=rtl]  {
    @import "_rtl.scss";
}

* {
    box-sizing: border-box;
}

html, body {
    font-size: var(--font-m);
    line-height: normal;
}

